<template>
    <div class="login-container">
        <div class="title mr-b3">{{ "访客登录" | language }}</div>
        <Form
            :model="formItem"
            :label-width="180"
            ref="formInline"
            :rules="ruleInline"
            class="login-form"
        >
            <FormItem :label="'邮箱' | language" prop="email">
                <Input
                    v-model="formItem.email"
                    style="width:360px;"
                    type="email"
                    :placeholder="'请输入邮箱' | language"
                />
            </FormItem>
            <FormItem :label="'验证码' | language" prop="code">
                <Input
                    v-model="formItem.code"
                    style="width:360px;"
                    :placeholder="'请输入验证码' | language"
                >
                    <Button
                        style="width:150px;"
                        slot="append"
                        @click="getCode"
                        >{{ text | language }}</Button
                    >
                </Input>
            </FormItem>
            <div class="login-tip">
                提示1：未注册邮箱号会直接注册。<br />
                提示2：请勿将验证码告知他人
            </div>

            <FormItem>
                <Button
                    type="primary"
                    style="width:320px;"
                    size="large"
                    @click="handleSubmit('formInline')"
                    >{{ "提交" | language }}</Button
                >
            </FormItem>
        </Form>
    </div>
</template>

<script>
import { languageTranslation } from "../../assets/util/language";
export default {
    data() {
        return {
            formItem: {
                email: "",
                code: ""
            },
            text: "获取验证码",
            time: 60,
            flag: false,
            ruleInline: {
                email: [
                    {
                        required: true,
                        message: languageTranslation("请输入邮箱"),
                        trigger: "blur"
                    },
                    {
                        type: "email",
                        message: languageTranslation("邮箱格式错误"),
                        trigger: "blur"
                    }
                ],
                code: [
                    {
                        required: true,
                        message: languageTranslation("请输入验证码"),
                        trigger: "blur"
                    }
                ]
            }
        };
    },
    methods: {
        goToRegister() {
            this.$router.push("/common/register");
        },
        async getCode() {
            if (
                !/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(
                    this.formItem.email
                )
            ) {
                return this.$Message.error("邮箱格式错误");
            }
            if (this.flag)
                return this.$Message.error(
                    languageTranslation("验证码已发送，请勿反复获取！")
                );
            this.flag = true;
            await this.$http.get(
                `/user/visitor/emailcode/${this.formItem.email}`
            );
            this.$Message.success(languageTranslation("验证码发送成功！"));
            let timer = setInterval(() => {
                this.time -= 1;
                if (this.time > 0) {
                    this.text = `${this.time}s${languageTranslation(
                        "后重新获取"
                    )}`;
                } else {
                    this.time = 60;
                    this.text = `${languageTranslation("获取验证码")}`;
                    this.flag = false;
                    clearInterval(timer);
                }
            }, 1000);
        },
        handleSubmit(name) {
            this.$refs[name].validate(async valid => {
                if (!valid) {
                    this.$Message.error(languageTranslation("登录失败!"));
                }
                let res = await this.$http.post("/user/visitor/login", {
                    email: this.formItem.email,
                    code: this.formItem.code
                });
                if (res) {
                    this.$store.dispatch("loginState", res);
                    this.$router.push("/");
                    this.$Message.success(languageTranslation("登录成功!"));
                    return;
                }
                this.$Message.error(
                    languageTranslation("登录失败，该账号未注册或已停止使用!")
                );
            });
        }
    }
};
</script>

<style lang="less" scoped>
.login-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
        font-size: 25px;
        font-weight: 600;
        opacity: 0.9;
    }
    .register {
        display: flex;
        justify-content: flex-end;
    }
    .login-tip {
        margin-bottom: 24px;
        color: #999;
        text-align: left;
        display: inline-block;
    }
}
@media screen and (max-width: 960px) {
    .login-container {
        .login-form {
            width: 100%;
            /deep/.ivu-form-item {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            /deep/.ivu-form-item-label {
                width: 60px !important;
            }
            /deep/.ivu-form-item-content {
                margin-left: 20px !important;
                flex: 1;
                /deep/.ivu-btn {
                    width: 100px !important;
                }
            }
            /deep/.ivu-input-wrapper {
                width: 100% !important;
            }
        }
    }
}
</style>
